<div class="mt-5 rounded bg-white px-5 py-6 shadow-md">
  <div class="block md:flex justify-between">
    <div>
      <table class="w-full" v-if="getSelectedCompany">
        <tbody>
          <tr>
            <td>Tên công ty:</td>
            <td class="font-bold">{{ getSelectedCompany.tenDoanhNghiep }}</td>
          </tr>
          <tr>
            <td>Mã số thuế:</td>
            <td class="font-bold">{{ getSelectedCompany.mst }}</td>
          </tr>
          <tr>
            <td>Năm bắt đầu kê khai:</td>
            <td class="font-bold">{{ getSelectedCompany.start_year }}</td>
          </tr>
        </tbody>
      </table>
      <div v-else>
        <h3 class="font-bold text-xl">Bạn chưa quản lý công ty</h3>
        <p class="font-normal">Vui lòng tạo công ty</p>
      </div>
    </div>

    <div class="flex items-center">
      <small class="italic font-bold" v-if="message">*{{ message }}</small>
      <div class="ml-0 mt-3 md:mt-0 md:ml-3 w-28">
        <el-select
          v-model="selectYear"
          @change="changeYear()"
          placeholder="Năm"
          :disabled="!getSelectedCompany"
        >
          <el-option
            v-for="(item, index) in arrYear"
            :value="item"
            :key="index"
          >
            {{ item }}
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</div>

<div class="mt-5 rounded">
  <div class="grid grid-cols-4 gap-6">
    <div
      class="
        px-5
        py-3
        rounded
        shadow-md
        bg-white
        col-span-4
        md:col-span-2
        xl:col-span-1
        border-r-2
        flex
        items-center
      "
    >
      <div class="bg-icon mr-3 p-2 rounded">
        <img
          src="@/assets/icons/icon_sum_invoice.svg"
          alt=""
          class="w-10 h-10 fill-color"
        />
      </div>
      <div>
        <p class="font-bold">{{ formatNumber(total_invoice) }}</p>
        <p>Tổng số hóa đơn</p>
      </div>
    </div>
    <div
      class="
        px-5
        py-3
        rounded
        shadow-md
        bg-white
        col-span-4
        md:col-span-2
        xl:col-span-1
        border-r-2
        flex
        items-center
      "
    >
      <div class="bg-icon mr-3 p-2 rounded">
        <img
          src="@/assets/icons/icon_invoice_month.svg"
          alt=""
          class="w-10 h-10 fill-color"
        />
      </div>
      <div>
        <p class="font-bold">{{ formatNumber(total_invoice_in_year) }}</p>
        <p>Số hóa đơn trong năm</p>
      </div>
    </div>
    <div
      class="
        px-5
        py-3
        rounded
        shadow-md
        bg-white
        col-span-4
        md:col-span-2
        xl:col-span-1
        border-r-2
        flex
        items-center
      "
    >
      <div class="bg-icon-user mr-3 p-2 rounded">
        <img
          src="@/assets/icons/icon_cash.svg"
          alt=""
          class="w-10 h-10 fill-color"
        />
      </div>
      <div>
        <p class="font-bold">{{ formatNumber(total_bought) }}</p>
        <p class="mt-1">Mua vào</p>
      </div>
    </div>
    <div
      class="
        px-5
        py-3
        rounded
        shadow-md
        bg-white
        col-span-4
        md:col-span-2
        xl:col-span-1
        border-r-2
        flex
        items-center
      "
    >
      <div class="bg-icon-user mr-3 p-2 rounded">
        <img
          src="@/assets/icons/icon_cash.svg"
          alt=""
          class="w-10 h-10 fill-color"
        />
      </div>
      <div>
        <p class="font-bold">{{ formatNumber(total_sold) }}</p>
        <p class="mt-1">Bán ra</p>
      </div>
    </div>
  </div>
</div>
<div class="mt-5">
  <div class="grid grid-cols-2 gap-6">
    <div class="col-span-2 xl:col-span-1 rounded bg-white px-5 py-3 shadow-md">
      <canvas ref="myChartFirt" id="myChartFirt"></canvas>
    </div>
    <div class="col-span-2 xl:col-span-1 rounded bg-white px-5 py-3 shadow-md">
      <canvas ref="myChartSecond" id="myChartSecond"></canvas>
    </div>
  </div>
</div>
